// app/javascript/controllers/share_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String, shareableId: String, shareableType: String }

  connect() {
  }

  share() {
    if (navigator.share) {
      navigator.share({
        title: document.title,
        text: "Vote for your favourite influencers and earn points.",
        url: this.urlValue,
      })
      .then(() => {
        console.log("Shared successfully");
        this.createShareRecord();
      })
      .catch((error) => console.error("Error sharing:", error));
    } else {
      alert("Share not supported on this device.");
    }
  }

  // Send data to server to create a Share record
  createShareRecord() {
    fetch('/shares', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        share: {
          share_url: this.urlValue,
          title: document.title,
          shareable_id: this.shareableIdValue,
          shareable_type: this.shareableTypeValue,
          verified: true
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log("Share recorded");
    })
    .catch((error) => {
      console.error("Error share record");
    });
  }
}
