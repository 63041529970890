// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "@fortawesome/fontawesome-free/js/all";
import LocalTime from "local-time"
LocalTime.start()

// document.addEventListener("turbo:before-render", (event) => {
//   console.log("Turbo: before-render", event);
// });

// document.addEventListener("turbo:render", (event) => {
//   console.log("Turbo: render", event);
// });
