// app/javascript/controllers/timer_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["timeDisplay"];
  static values = {
    endTime: String,
  };

  connect() {
    this.endTime = new Date(this.endTimeValue); // Convert the ISO8601 string to a Date object
    this.update(); // Initialize the display
    this.timer = setInterval(() => this.update(), 1000); // Update every second
  }

  disconnect() {
    clearInterval(this.timer); // Clean up when the controller is disconnected
  }

  update() {
    const now = new Date();
    const remaining = Math.max(0, Math.floor((this.endTime - now) / 1000)); // Calculate remaining seconds

    if (remaining <= 0) {
      clearInterval(this.timer);
    }

    const hours = String(Math.floor(remaining / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((remaining % 3600) / 60)).padStart(2, "0");
    const seconds = String(remaining % 60).padStart(2, "0");

    this.timeDisplayTarget.textContent = `${hours}:${minutes}:${seconds}`;
  }
}
