import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["modal"];
  static values = { userVotes: Number, userName: String };

  connect() {
    // Initialize modal only once when the controller connects
    if (this.modalTarget) {
      this.bootstrapModal = new bootstrap.Modal(this.modalTarget);
      if (this.userVotesValue > 0 && this.userNameValue.length < 1) {
        this.bootstrapModal.show();
      }
    }
  }

  open() {
    if (this.bootstrapModal) {
      this.bootstrapModal.show();
    }
  }

  close() {
    if (this.bootstrapModal) {
      this.bootstrapModal.hide();
    }
  }
}
